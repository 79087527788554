import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import NavButtons from 'components/Software/Windows/Blue_Iris_v5/NavButtons';
import ForumBox from 'components/Software/Windows/Blue_Iris/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "BlueIris MQTT with Home Assistant",
  "path": "/Software/Windows/Blue_Iris_v5/Home_Assistant/",
  "dateChanged": "2020-06-12",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features. Use the INSTAR MQTT Service to connect BlueIris with your INSTAR Camera and Home Assistant",
  "image": "./P_SearchThumb_Blue_Iris.png",
  "social": "/images/Search/P_SearchThumb_Blue_Iris.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Blue-Iris_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Blue Iris v5' dateChanged='2020-06-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Blue_Iris_v5/Home_Assistant/' locationFR='/fr/Software/Windows/Blue_Iris_v5/Home_Assistant/' crumbLabel="Home Assistant" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "blueiris-mqtt-with-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#blueiris-mqtt-with-home-assistant",
        "aria-label": "blueiris mqtt with home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BlueIris MQTT with Home Assistant`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-alarmserver"
        }}>{`MQTT Alarmserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#controlling-blueiris-with-mqtt"
        }}>{`Controlling BlueIris with MQTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-the-live-video"
        }}>{`Adding the Live Video`}</a></li>
    </ul>
    <h2 {...{
      "id": "mqtt-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-alarmserver",
        "aria-label": "mqtt alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarmserver`}</h2>
    <p>{`We already configured our cameras in BlueIris to `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/#mqtt-alarm-service"
      }}>{`send an MQTT update once the software triggers an alarm`}</a>{`. In my case this was the MQTT Topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BlueIris/MotionAlarm/8015black/`}</code>{`. We receive the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` once an event was triggered and this alarm state falls back to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` a few seconds later.`}</p>
    <p>{`To use this event in Home Assistant we just have to create a `}<strong parentName="p">{`Binary Sensor`}</strong>{` using the `}<strong parentName="p">{`MQTT Platform`}</strong>{` inside the Home Assistant configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "785px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a84f582e6392b8bfcc5265ad393b103/cda19/BlueIris_MQTT_Home_Assistant_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAVklEQVQI12MQMjAS0jMUVtYVkdQSEdMUEdUAkRAkTgAxCJmbCFqaCpoag4xQ0RNW0RVW1RNW0xOW1Saon0HIwkTQygykU0NfREZLREpLRBpMSmoRtBkAu3EVexsYhnkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a84f582e6392b8bfcc5265ad393b103/e4706/BlueIris_MQTT_Home_Assistant_00.avif 230w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/d1af7/BlueIris_MQTT_Home_Assistant_00.avif 460w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/a7871/BlueIris_MQTT_Home_Assistant_00.avif 785w"],
              "sizes": "(max-width: 785px) 100vw, 785px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a84f582e6392b8bfcc5265ad393b103/a0b58/BlueIris_MQTT_Home_Assistant_00.webp 230w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/bc10c/BlueIris_MQTT_Home_Assistant_00.webp 460w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/f2d53/BlueIris_MQTT_Home_Assistant_00.webp 785w"],
              "sizes": "(max-width: 785px) 100vw, 785px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a84f582e6392b8bfcc5265ad393b103/81c8e/BlueIris_MQTT_Home_Assistant_00.png 230w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/08a84/BlueIris_MQTT_Home_Assistant_00.png 460w", "/en/static/4a84f582e6392b8bfcc5265ad393b103/cda19/BlueIris_MQTT_Home_Assistant_00.png 785w"],
              "sizes": "(max-width: 785px) 100vw, 785px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a84f582e6392b8bfcc5265ad393b103/cda19/BlueIris_MQTT_Home_Assistant_00.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now restart Home Assistant and create an `}<strong parentName="p">{`Entity`}</strong>{` in the Lovelace UI that uses our sensor:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "841px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/bad1b/BlueIris_MQTT_Home_Assistant_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAt0lEQVQI1yWHi26DMAwA+SimErLEFo/YhiSEDQhptar//xVTqXQ63VXr/ifxCGsJqfh0+vXtOZ1TzMvPffl9+FQ47ApYoSiU9uLT1RQP5zeXMq+FwkF+o7Bd3t2SKWU3bx2vnSW2RJZGFAdM11bKUgNcu1BTuKEoQ8qSMtSg1BRrFxTyF3DRw0v3xYy5n86O79/DU/eVRtEoagwNxU+/AWn7+caxodh2s0YxyAAMlrQLZvBgCYD/AdKzQpz4HWeuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4ca8dc77292d8c5f232b22dc714dd42/e4706/BlueIris_MQTT_Home_Assistant_01.avif 230w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/d1af7/BlueIris_MQTT_Home_Assistant_01.avif 460w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/f15cc/BlueIris_MQTT_Home_Assistant_01.avif 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4ca8dc77292d8c5f232b22dc714dd42/a0b58/BlueIris_MQTT_Home_Assistant_01.webp 230w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/bc10c/BlueIris_MQTT_Home_Assistant_01.webp 460w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/d02c2/BlueIris_MQTT_Home_Assistant_01.webp 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4ca8dc77292d8c5f232b22dc714dd42/81c8e/BlueIris_MQTT_Home_Assistant_01.png 230w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/08a84/BlueIris_MQTT_Home_Assistant_01.png 460w", "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/bad1b/BlueIris_MQTT_Home_Assistant_01.png 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4ca8dc77292d8c5f232b22dc714dd42/bad1b/BlueIris_MQTT_Home_Assistant_01.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "838px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3665a8237fdf7860dae22f0fc7324581/a1dd2/BlueIris_MQTT_Home_Assistant_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAw0lEQVQoz42QW1LEIBBFWZfmBSQZQgMNQmeSGdAZ978IK2r5aag6v6fug10gBirgNxt2TyVQ6Uf70qvXYTmFiRmVXcclSOUvJs0QWwE15iEvdqX9iTEP029gw3Ur4JSGazZDCus7piyVr9T+YIAb7Z/x+ljc2gropKmhH20nDfPxzienLGm8Yrx30lS+dWxWluL24VN28dZwXW8eMp8RU1aWlKXvMudXN1z/wFoBnop9u2HMnooJ+//Lh8nxGYfJdRK+AH9Rk3+Ku3/xAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3665a8237fdf7860dae22f0fc7324581/e4706/BlueIris_MQTT_Home_Assistant_02.avif 230w", "/en/static/3665a8237fdf7860dae22f0fc7324581/d1af7/BlueIris_MQTT_Home_Assistant_02.avif 460w", "/en/static/3665a8237fdf7860dae22f0fc7324581/0027b/BlueIris_MQTT_Home_Assistant_02.avif 838w"],
              "sizes": "(max-width: 838px) 100vw, 838px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3665a8237fdf7860dae22f0fc7324581/a0b58/BlueIris_MQTT_Home_Assistant_02.webp 230w", "/en/static/3665a8237fdf7860dae22f0fc7324581/bc10c/BlueIris_MQTT_Home_Assistant_02.webp 460w", "/en/static/3665a8237fdf7860dae22f0fc7324581/7820a/BlueIris_MQTT_Home_Assistant_02.webp 838w"],
              "sizes": "(max-width: 838px) 100vw, 838px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3665a8237fdf7860dae22f0fc7324581/81c8e/BlueIris_MQTT_Home_Assistant_02.png 230w", "/en/static/3665a8237fdf7860dae22f0fc7324581/08a84/BlueIris_MQTT_Home_Assistant_02.png 460w", "/en/static/3665a8237fdf7860dae22f0fc7324581/a1dd2/BlueIris_MQTT_Home_Assistant_02.png 838w"],
              "sizes": "(max-width: 838px) 100vw, 838px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3665a8237fdf7860dae22f0fc7324581/a1dd2/BlueIris_MQTT_Home_Assistant_02.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We will now be notified in Home Assistant when BlueIris triggers an alarm event and updates the alarm topic for our camera:`}</p>
    <p><img parentName="p" {...{
        "src": "./BlueIris_MQTT_Home_Assistant_03.gif",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <h2 {...{
      "id": "controlling-blueiris-with-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#controlling-blueiris-with-mqtt",
        "aria-label": "controlling blueiris with mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Controlling BlueIris with MQTT`}</h2>
    <p>{`Now to something more useful - we can use this alarm input to switch the camera that caused the alarm into `}<strong parentName="p">{`Fullscreen Mode`}</strong>{`. To do this we have to send the message payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera=black&fullscreen=1`}</code>{` when an alarm was triggered and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera=black&escape`}</code>{` once the alarm event ended to the BlueIris Admin MQTT Topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BlueIris/admin`}</code>{` (see `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/#admin-commands"
      }}>{`BlueIris Admin Command Topics`}</a>{` for more options).`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`black`}</code>{` in this payloads is the `}<a parentName="p" {...{
          "href": "/en/Software/Windows/Blue_Iris_v5/#blue-iris-setup"
        }}>{`camera short name`}</a>{` that I assigned to this camera in BlueIris - please substitute it with your cameras short name.`}</p>
    </blockquote>
    <p>{`This can be done from the Lovelace UI by creating an `}<strong parentName="p">{`Automation`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/65abffd48412638a680af68f055bb4ed/5a190/BlueIris_MQTT_Home_Assistant_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+klEQVQ4y42TiW6jMBCG+1IrtU248QG2scG3QwK0TY/3f4EVoUcg2mqlT9bI+J/BM7/vHmIEamnCU2eO9vDcdEGHSbohBewxwbu0+oW7xwSnoGFtaGQgwlHhiLCsC/8l3qXVPqvjgsYFjXISFzQpWQqa5N/EJfupnJRMhWeueyZ7rnrc2AyJouo2lLVcyHH7I45LZo9nezy3ZujcRLsDpHoBfQWQakDUwlpcUH88h9OrdKMOT9JPpn+Z0/Uv/vSqwlOYPqQbQS0hUZCo4lqcFrRQgfhRmkHoU2sG1h0QM7ix+LJW3H2uwiN5AEz/NCxKqz+1yKQXcr52o/qqsQloUsg3JFhETCWIXzUMNNqNlNuHGC38MqR9sh5VlBPaekjkPqujnEQZ2Wf1Eu+yaol3aXWbca6cQc5aT7jlqqedJ8KxbjYMk4HLQLib91u3pNiKy6oNw6v2o+uftR9bezJhknZQbqiYAXWHiMJMJyXdFP8cFbqMETENarmc2KfVLsH3EXyI4X2E7iP4mOBvVnfOkchxmyGRITH3eYbPXHf7sp9BnkG+cliYPoQ5lbUEREGqv514y/J15W1hBsQMuNgQNxYQdevtbzIkVpX98NZP72F898ObP73Z/oyYgVfGvqaoulVlKnumjjV3OW43b+iW7cMARP1yeiv++u2/vzhIrT941UQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65abffd48412638a680af68f055bb4ed/e4706/BlueIris_MQTT_Home_Assistant_04.avif 230w", "/en/static/65abffd48412638a680af68f055bb4ed/d1af7/BlueIris_MQTT_Home_Assistant_04.avif 460w", "/en/static/65abffd48412638a680af68f055bb4ed/c9875/BlueIris_MQTT_Home_Assistant_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/65abffd48412638a680af68f055bb4ed/a0b58/BlueIris_MQTT_Home_Assistant_04.webp 230w", "/en/static/65abffd48412638a680af68f055bb4ed/bc10c/BlueIris_MQTT_Home_Assistant_04.webp 460w", "/en/static/65abffd48412638a680af68f055bb4ed/d00b9/BlueIris_MQTT_Home_Assistant_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65abffd48412638a680af68f055bb4ed/81c8e/BlueIris_MQTT_Home_Assistant_04.png 230w", "/en/static/65abffd48412638a680af68f055bb4ed/08a84/BlueIris_MQTT_Home_Assistant_04.png 460w", "/en/static/65abffd48412638a680af68f055bb4ed/5a190/BlueIris_MQTT_Home_Assistant_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/65abffd48412638a680af68f055bb4ed/5a190/BlueIris_MQTT_Home_Assistant_04.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36650eaf730b09c9152617877dfcabbc/5a190/BlueIris_MQTT_Home_Assistant_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACqklEQVQ4y32TWWsTURiG85fEajuZZObMes7MmX1P2jSxpgsoLlSEoiB4409wKVWwFKtVavEfdEvslhRExbZC8cZGbwr1wkSoZqZb0rHw8DEM83LOx/NOgqAgVFwv2yfIFkHBJI0IChIUIigUPh9PEkhtJEggpVnMIYNDhogdUbaR6kLFhYoDsY1UB2JH0jxeMk/nE0kaMaKOjUDSfUnzBckCggoELZwqI2pA0Fho0LwSdzKNKF4Fog5EnUUmEPU0i9Oc0kL4huJVWtBoQUtzyvHJQNT9XL/p5znJ4iRLwDYvx8DJtqQHSPVYdLBCeG1o+LkBy88j1YOqi9TmhhyKgUUmJ1lA1E+ERT3oHXSyfUGuGPT2+7kiVD1RcWORzSwDjZaw4eUVKwugQfHaIQcbtgFEnRY0EoThFCN3poXbI/er1a1SaXlhoTQ7t7iyWqlU1z9++ry59XVjc+uILxub29vfhq4On+9i0yxuhi+mhGs3R8rlyux8aW5+cW5+sby0urS81mSlcsT75bXy0mp1/cPloesdBJtm5QQJUOQjCVASSBEEjU5DMjLJyEkgtahioBHkB2Uzw8u2gB1RcWNVYTMjGxmk+VybKifTJyqugJ0oHAlvgz2tiqAgh0zdzQnYjmQI/5EUAVXvWFX0Y0RiopKeRfgNxasn66mZQUGxupsdlKxoxiLrAWyvp6h73UU7KAjYCbvtnJFvrycQdbenaHp5J3PJzhTsTAFqHlTjwWa25WQWGX6u33B7eNniQiskgwkadcVBMpjiDndOASlJo4Erw+MTM0/Gxh+NPnv4+Ono2POJyTcvp2Ymp96e5MWr6dfT77oLgxdIPsXIiTSLO5Lc3XsPdnfrP2o/a7Xazvedvb1f9Xqj0fjTxu96Y39//8atO+c6wb9u/wWyN781LIoVFgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36650eaf730b09c9152617877dfcabbc/e4706/BlueIris_MQTT_Home_Assistant_05.avif 230w", "/en/static/36650eaf730b09c9152617877dfcabbc/d1af7/BlueIris_MQTT_Home_Assistant_05.avif 460w", "/en/static/36650eaf730b09c9152617877dfcabbc/c9875/BlueIris_MQTT_Home_Assistant_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36650eaf730b09c9152617877dfcabbc/a0b58/BlueIris_MQTT_Home_Assistant_05.webp 230w", "/en/static/36650eaf730b09c9152617877dfcabbc/bc10c/BlueIris_MQTT_Home_Assistant_05.webp 460w", "/en/static/36650eaf730b09c9152617877dfcabbc/d00b9/BlueIris_MQTT_Home_Assistant_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36650eaf730b09c9152617877dfcabbc/81c8e/BlueIris_MQTT_Home_Assistant_05.png 230w", "/en/static/36650eaf730b09c9152617877dfcabbc/08a84/BlueIris_MQTT_Home_Assistant_05.png 460w", "/en/static/36650eaf730b09c9152617877dfcabbc/5a190/BlueIris_MQTT_Home_Assistant_05.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36650eaf730b09c9152617877dfcabbc/5a190/BlueIris_MQTT_Home_Assistant_05.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When Home Assistant receives an update with payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` for the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BlueIris/MotionAlarm/8015black/`}</code>{` it will now tell BlueIris to switch the camera with the short name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`black`}</code>{` (you have to adjust this according to your setup) to full screen, wait for 15s and escape back to idle.`}</p>
    <h2 {...{
      "id": "adding-the-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-the-live-video",
        "aria-label": "adding the live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Live Video`}</h2>
    <p>{`And last but not least, let us add the live stream from our cameras. We already did this for our `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/#adding-your-cameras-live-video"
      }}>{`cameras directly by using the snapshot path`}</a>{`. We can now extend the configuration for the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/#access-to-the-live-video-of-one-camera-or-all-cameras"
      }}>{`BlueIris MJPEG video source`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "786px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2c53cd9df1402cd947e05d381bdfad7/321ea/BlueIris_MQTT_Home_Assistant_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABo0lEQVQ4y5WT227aQBRF+QBi6hlfuJQ0IcUez9U2FFSU0KRqpbRq//9zVmWDSiqlCjwsbek8bO19zsxg9HVH9Ht/4Nee6MfDiedO90Tf7xk97Yi+3RP93B9mr/H8wECUjrhpiH2N0B45rZBpiZxVyLlGTqp+lqQlSbR8k4GwNeJxi1ytkJsVsmlIdCAxgXRc9cjJ0Xj8NoNdYdE24JTDFS8oHe5a92zGijxTyLwiyQ/6PwbWBYb3n/p0wtXIhUVeG+SdQ95a4huLGytmmUIcDZNeX2egledqvUKamrht6VfQ0dWWJSJRmFwxzRUiO8PQdIZtizThlO69Qd5Y5NIRLxzmooQ2MHzaIuqGeNUiTI0oPEJ5kkmFGFd9wtnZCZeOK1sjdehNxNIhVDgZTvRlhtrXDB+3iKZBVMfaM/2isr2ssincYYc69A/871HMv0c5O2GlPcNud3WD+OgQH+yBhUXcWt4tHHpaMT03YXCB0ZcN6bolaVtSHcjuHGnpyeaadG4Ikwsqfy4cdunwC4svXI+rPL50hLQkZCXrXJF1P+SMyn8AASWD3EVe2cUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2c53cd9df1402cd947e05d381bdfad7/e4706/BlueIris_MQTT_Home_Assistant_06.avif 230w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/d1af7/BlueIris_MQTT_Home_Assistant_06.avif 460w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/d1b3a/BlueIris_MQTT_Home_Assistant_06.avif 786w"],
              "sizes": "(max-width: 786px) 100vw, 786px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2c53cd9df1402cd947e05d381bdfad7/a0b58/BlueIris_MQTT_Home_Assistant_06.webp 230w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/bc10c/BlueIris_MQTT_Home_Assistant_06.webp 460w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/4cb1e/BlueIris_MQTT_Home_Assistant_06.webp 786w"],
              "sizes": "(max-width: 786px) 100vw, 786px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2c53cd9df1402cd947e05d381bdfad7/81c8e/BlueIris_MQTT_Home_Assistant_06.png 230w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/08a84/BlueIris_MQTT_Home_Assistant_06.png 460w", "/en/static/f2c53cd9df1402cd947e05d381bdfad7/321ea/BlueIris_MQTT_Home_Assistant_06.png 786w"],
              "sizes": "(max-width: 786px) 100vw, 786px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2c53cd9df1402cd947e05d381bdfad7/321ea/BlueIris_MQTT_Home_Assistant_06.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now restart Home Assistant and create an `}<strong parentName="p">{`Entity`}</strong>{` in the Lovelace UI that uses those cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c527ce185ddd45da892e5394dd0fa83d/22475/BlueIris_MQTT_Home_Assistant_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABAElEQVQY023M2U6DQACFYe69MjF2mI0CLYXGVLuAYNkGmBmmLG2kre//JkY0UZMmX87dfzTdtKFpI8uhc4/MXMNZmu4TcTwyd5Ht3GY5cObqjqdhamI6BYjqI4iNH4gCSG7SJ+h+sbjbvmjEsDG1AKQAjeA/OjYQmcK/sAEgmSA6wVR73oSiGRhvS94Voi/E1zLeVbIvZR/n9TZi/lsRRMyPWBBXy5X/CNB4TbT1bq+6a9VdytMHb8/8MPDDUDVDebxW7YXz/j0Rba7a4tDk9SVX0SZ8AEj/jlfrcJ+pIK93og0zGSUijMUrUzvZB0wlqTzGXKVC8qbO5CmVwfo3/gTEwj/uEmuQaQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c527ce185ddd45da892e5394dd0fa83d/e4706/BlueIris_MQTT_Home_Assistant_07.avif 230w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/d1af7/BlueIris_MQTT_Home_Assistant_07.avif 460w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/7f308/BlueIris_MQTT_Home_Assistant_07.avif 920w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/a6063/BlueIris_MQTT_Home_Assistant_07.avif 1039w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c527ce185ddd45da892e5394dd0fa83d/a0b58/BlueIris_MQTT_Home_Assistant_07.webp 230w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/bc10c/BlueIris_MQTT_Home_Assistant_07.webp 460w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/966d8/BlueIris_MQTT_Home_Assistant_07.webp 920w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/525da/BlueIris_MQTT_Home_Assistant_07.webp 1039w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c527ce185ddd45da892e5394dd0fa83d/81c8e/BlueIris_MQTT_Home_Assistant_07.png 230w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/08a84/BlueIris_MQTT_Home_Assistant_07.png 460w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/c0255/BlueIris_MQTT_Home_Assistant_07.png 920w", "/en/static/c527ce185ddd45da892e5394dd0fa83d/22475/BlueIris_MQTT_Home_Assistant_07.png 1039w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c527ce185ddd45da892e5394dd0fa83d/c0255/BlueIris_MQTT_Home_Assistant_07.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "840px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e36d93ac23e6fa198af187a14674f68a/1e088/BlueIris_MQTT_Home_Assistant_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3UlEQVQoz5XRWZKDIBgEYK41ibKIKPumgiTR5P6nmCLJ+zBV/fpV0z9AuT2mU/vdxntIp98OSNUF8SsWfwZQHrhJlHs6+1mtTMSOyBZZsQ5lyS8Tb5DqHzhfEO+IbAwQLof0dMuDTK4jsh9Ue2rzWl4xPye1/AtDqgGdPaRKuaz8bpd7P6jGwXXz59phO0wo7af6YiaiCTduNiZjO/vUADQavx3K7dLlls1oNMPkyGR7IgFmtmJf0GhaMKQaM0smV6+NmXXrQ9jU+FVvbDCzXxzSqUORtvXZ72aLRvMLEs3DY4s/f58AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e36d93ac23e6fa198af187a14674f68a/e4706/BlueIris_MQTT_Home_Assistant_08.avif 230w", "/en/static/e36d93ac23e6fa198af187a14674f68a/d1af7/BlueIris_MQTT_Home_Assistant_08.avif 460w", "/en/static/e36d93ac23e6fa198af187a14674f68a/32fee/BlueIris_MQTT_Home_Assistant_08.avif 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e36d93ac23e6fa198af187a14674f68a/a0b58/BlueIris_MQTT_Home_Assistant_08.webp 230w", "/en/static/e36d93ac23e6fa198af187a14674f68a/bc10c/BlueIris_MQTT_Home_Assistant_08.webp 460w", "/en/static/e36d93ac23e6fa198af187a14674f68a/ed60d/BlueIris_MQTT_Home_Assistant_08.webp 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e36d93ac23e6fa198af187a14674f68a/81c8e/BlueIris_MQTT_Home_Assistant_08.png 230w", "/en/static/e36d93ac23e6fa198af187a14674f68a/08a84/BlueIris_MQTT_Home_Assistant_08.png 460w", "/en/static/e36d93ac23e6fa198af187a14674f68a/1e088/BlueIris_MQTT_Home_Assistant_08.png 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e36d93ac23e6fa198af187a14674f68a/1e088/BlueIris_MQTT_Home_Assistant_08.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you save the entity you will see a list of all your BlueIris cameras with a tiny preview image. Click on those previews to open up the full video stream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19c21cdf479f132b0eb47a9fa9a36617/e24fe/BlueIris_MQTT_Home_Assistant_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC50lEQVQozwHcAiP9AG+ImXaPn32VpXePoHeQoXePoHaPn3SMnXKLnGuDknyDe4uPgpGVipWXjpSWjY2SiYaLgoKHfX2EfHqCegCtxMmzyc+5z9TA1tzH29/I2t7F2N3C1tu80tirwcXRwJ3w0aL41qT/46P/26X30KToyqLZxaLSwqLGvKEAkIyDkI2EmJOMo5mQpZiPnZSMnpiQi4uJiIWDe3p6fXt9ioOEl4J+wIt1vo1+noeEi4OEf4CGfIGGcnyBAH94bIZ2XYt7Y4x+bJeGcJN9ZW1pYnVuYn9xX2ZmYVReaFZldmZxf25qdXNnb2dpdFVkdlJjc0tgb0debgBdYFl1bFN1Z0yIfGGDfG55c21GSUtpY1dZV1JASE1OWWRRXm9YZXVZY3FcZHNYWmdTX29NX3BHXG1EXHEAQEZFS0xBRUhEVFFIWVhSNzo/OEJLV11iOUdUPEJIQ1BeSlpqSlZnTVhpTltuT1hmQ0xaP1FiO05fP1VmAA82VitYgD5qklF3mFyAnWGHomCHpE1wjSdDWQ4eKW5wc4+Tm4uOlY+Sl5OUlpqUj4iMkI2LhZqVh4iOjgAMFxk9T1dwi5uMr8Stydiyy9V6kJtXaHFWaG8tO0J9eXyopKWZmZqqq66PkZWJiYmKjpCcl4eUj3+jnIkACBEKLzAZlYFQrKqUsrCeo56JhoJyl5F2mI9xV1FAaGxujJOZe4KImZmakJOakYuIhXltcXVwZ25ud3dxAAwLAzsrDmxTJH5xR5KBUpiFU2tfP11UQUlAMSEeFmhtcIaMkneBiXmFjm13hJmGeYd1ZlllbXt+e3h5cwALBwETEgMcJAtGUTt1bkVvZjg/Qh4wNRcdHhYLDA1UX2h9f4CahXViaG9hbHRyZmB+dW1RWmFQVlhQXGIAAAAAAAUABxUHFyYXLDUfIiwWDR0OBhIECA4ADBAMTlxrVFpih21bWVpgTE9STFJXQ0xSO0VONENQMD9NDOM95PKeJkEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19c21cdf479f132b0eb47a9fa9a36617/e4706/BlueIris_MQTT_Home_Assistant_09.avif 230w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/d1af7/BlueIris_MQTT_Home_Assistant_09.avif 460w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/7f308/BlueIris_MQTT_Home_Assistant_09.avif 920w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/068a9/BlueIris_MQTT_Home_Assistant_09.avif 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19c21cdf479f132b0eb47a9fa9a36617/a0b58/BlueIris_MQTT_Home_Assistant_09.webp 230w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/bc10c/BlueIris_MQTT_Home_Assistant_09.webp 460w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/966d8/BlueIris_MQTT_Home_Assistant_09.webp 920w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/e9b9c/BlueIris_MQTT_Home_Assistant_09.webp 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19c21cdf479f132b0eb47a9fa9a36617/81c8e/BlueIris_MQTT_Home_Assistant_09.png 230w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/08a84/BlueIris_MQTT_Home_Assistant_09.png 460w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/c0255/BlueIris_MQTT_Home_Assistant_09.png 920w", "/en/static/19c21cdf479f132b0eb47a9fa9a36617/e24fe/BlueIris_MQTT_Home_Assistant_09.png 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19c21cdf479f132b0eb47a9fa9a36617/c0255/BlueIris_MQTT_Home_Assistant_09.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      